<template>
  <div class="tilisy-consent">
    <div v-if="error" class="tilisy-error">
      <p class="text-justify">{{ error }}</p>
    </div>
    <div v-if="!error && !starting" class="tilisy-terms">
      <p class="text-justify">
        <i18n tag="span" path="Authentication is initiated by {0}."><strong>{{ appName }}</strong></i18n>
        <span>&nbsp;</span>
        <i18n tag="span" path="After you complete authentication, {0} will be shared with the authentication initiator." class="mr-1"><em>{{ $t(accessScope) }}</em></i18n>
        <span>&nbsp;</span>
        <i18n tag="span" path="By pressing {0} button you agree with the {1}."><span>"{{ externalConfirm || $t('Continue authentication') }}"</span><a :href="termsUrl" target="_blank">{{ $t('terms of Enable Banking API service') }}</a></i18n>
        <span v-if="canCancel">&nbsp;</span>
        <i18n tag="span" path="Press {0} button if you are not willing to share your payment accounts data." v-if="canCancel"><span>"{{ $t('Cancel') }}"</span></i18n>
      </p>
    </div>
    <div v-if="!error && !starting && (!externalConfirm || canCancel)" class="tilisy-confirmation">
      <div class="form-inline justify-content-center" v-if="!externalConfirm">
        <button class="btn btn-theme" v-on:click="submitConsent" :disabled="confirming || confirmed">{{ $t('Continue authentication') }}</button>
      </div>
      <div class="text-center" v-if="canCancel">
        <button class="btn btn-link" v-on:click="cancelAuth" :disabled="confirming || confirmed">{{ $t('Cancel') }}</button>
      </div>
    </div>
    <div v-if="!error && !starting" class="tilisy-about">
      <p><strong>{{ $t('About {appName}', { appName: appName }) }}</strong></p>
      <p class="text-justify">{{ appDescriptionLocal }}</p>
      <about-service />
    </div>
  </div>
</template>

<script>
import { i18n } from '../i18n.js'
import config from '../config'
import AboutService from '../components/AboutService.vue'

export default {
  components: { AboutService },
  i18n,
  props: {
    authorization: {
      type: String
    },
    locale: {
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: ''
    },
    sandbox: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    noRedirect: {
      type: Boolean,
      default: false
    },
    externalConfirm: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      apiOrigin: null,
      appName: '',
      appDescription: '',
      accessScope: 'your payment accounts data',
      error: '',
      starting: true,
      confirming: false,
      confirmed: false
    }
  },
  computed: {
    termsUrl () {
      return (this.apiOrigin || config.publicOrigin) + '/terms' + (
        this.locale ? `?locale=${this.locale.toUpperCase()}` : ''
      )
    },
    appDescriptionLocal: function () {
      if (typeof this.appDescription === 'string' && this.appDescription.length > 0) {
        return this.appDescription
      } else if (
        this.appDescription &&
        typeof this.appDescription[this.$i18n.locale] === 'string' &&
        this.appDescription[this.$i18n.locale].length > 0
      ) {
        return this.appDescription[this.$i18n.locale]
      } else {
        return this.$t('Application description is not provided.')
      }
    }
  },
  async mounted () {
    if (this.locale) {
      this.$i18n.locale = this.locale.toUpperCase()
    }
    this.apiOrigin = this.sandbox ? config.publicSandboxOrigin : config.publicOrigin
    if (this.origin) {
      this.apiOrigin = this.origin
    }
    try {
      const r = await fetch(
        this.apiOrigin + '/api/start_consent_external',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            authorization_id: this.authorization
          })
        }
      )
      const response = (await r.json()).response
      this.appName = response.app_name
      this.appDescription = response.app_description
      if (response.access) {
        if (response.access.balances && response.access.transactions) {
          this.accessScope = 'the list of your payment accounts, accounts balances and transactions'
        } else if (response.access.balances) {
          this.accessScope = 'the list of your payment accounts and accounts balances'
        } else if (response.access.transactions) {
          this.accessScope = 'the list of your payment accounts and accounts transactions'
        } else {
          this.accessScope = 'the list of your payment accounts'
        }
      }
      if (this.externalConfirm) {
        const comp = this
        this.$el.parentNode.addEventListener('confirm', function () { comp.submitConsent() })
      }
      this.$emit('ready')
    } catch (e) {
      this.setError(this.$t('Error while updating authentication status'))
    } finally {
      this.starting = false
    }
  },
  methods: {
    async submitConsent () {
      this.confirming = true
      try {
        await fetch(
          this.apiOrigin + '/api/confirm_consent_external',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              authorization_id: this.authorization
            })
          }
        )
        this.confirmed = true
        this.$emit('confirmed')
        if (!this.noRedirect) {
          let redirectUrl = this.apiOrigin + `/ais/start?sessionid=${this.authorization}`
          if (this.locale) {
            redirectUrl += `&locale=${this.locale.toUpperCase()}`
          }
          window.location.href = redirectUrl
        }
      } catch (e) {
        this.setError(this.$t('Unable to continue authorization'))
      } finally {
        this.confirming = false
      }
    },
    async cancelAuth () {
      this.$emit('cancelled')
    },
    setError (msg) {
      this.error = msg
      this.$emit('error', msg)
    }
  }
}
</script>

<style lang="scss">
</style>
