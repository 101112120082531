<template>
  <label class="radio-chips" @click="selectAspsp">
    <div class="radio-chips__content">
      <span v-if="beta" class="beta-flag">β</span>
      <div class="radio-chips__pic">
        <img :src="imageUrl" :alt="title">
      </div>
      <span class="radio-chips__title" v-html="highlightedTitle"></span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    beta: {
      type: Boolean,
      required: true
    },
    searchTerm: {
      type: String,
      default: ''
    }
  },
  computed: {
    highlightedTitle () {
      const indexFrom = this.title.toLowerCase().indexOf(this.searchTerm.toLowerCase())
      const substr = this.title.substr(indexFrom, this.searchTerm.length)

      return this.title.replace(substr, `<span class="highlight_text">${substr}</span>`)
    }
  },
  methods: {
    selectAspsp () {
      this.$emit('card-select', { title: this.title, beta: this.beta })
    }
  }
}
</script>

<style scoped>
.radio-chips__content {
  border: 1px solid #ebeaea;
  display: block;
  padding: 0.53rem 1rem;
  border-radius: 0.2rem;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.radio-chips__pic {
  height: 40px;
  max-width: 120px;
  display: block;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.radio-chips__pic img {
  max-height: 50%;
  max-width: 100%;
}
.radio-chips__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.beta-flag {
  float: right;
}

::v-deep .highlight_text{
  background-color:#BDBDBD
}
</style>
