<template>
  <div class="enablebanking-aspsp-list">
    <Spinner v-if="isLoading" />
    <div v-else class="card-list-container">
      <div class="card-list">
        <Card v-for="(card, index) in filteredCards" :key="index" :image-url="`${card.logo}${logoTransform}`" :title="card.name"
          :beta="card.beta" :searchTerm="searchTerm" @card-select="handleCardClick"></Card>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '../i18n.js'
import config from '../config'
import Card from '../components/elements/Card.vue'
import Spinner from '../components/elements/Spinner.vue'

export default {
  components: {
    Card,
    Spinner
  },
  i18n,
  data () {
    return {
      observer: null,
      aspsps: [],
      isLoading: false,
      logoTransform: '',
      searchTerm: '',
      brokerOrigin: '',
      sandbox: false,
      country: '',
      psuType: 'personal',
      service: 'AIS'
    }
  },
  async mounted () {
    this.initObserver()
    this.updateAttributes()

    await this.fetchAspsps()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    updateAttributes () {
      const sandboxAttr = this.$el.parentElement.getAttribute('sandbox')
      this.sandbox = sandboxAttr != null ? sandboxAttr !== 'false' : false
      this.brokerOrigin = this.$el.parentElement.getAttribute('origin') != null ? this.$el.parentElement.getAttribute('origin') : (this.sandbox ? config.publicSandboxOrigin : config.publicOrigin)
      this.searchTerm = this.$el.parentElement.getAttribute('search-term') != null ? this.$el.parentElement.getAttribute('search-term') : ''
      this.country = this.$el.parentElement.getAttribute('country') != null ? this.$el.parentElement.getAttribute('country') : ''
      this.psuType = this.$el.parentElement.getAttribute('psu-type') != null ? this.$el.parentElement.getAttribute('psu-type') : 'personal'
      this.service = this.$el.parentElement.getAttribute('service') != null ? this.$el.parentElement.getAttribute('service') : 'AIS'
      this.logoTransform = this.$el.parentElement.getAttribute('logo-transform') != null ? this.$el.parentElement.getAttribute('logo-transform') : ''
    },
    initObserver () {
      const callback = () => {
        this.$nextTick(() => {
          this.updateAttributes()
        })
      }
      const observer = new MutationObserver(callback)
      observer.observe(this.$el.parentElement, {
        attributes: true // configure it to listen to attribute changes
      })
      this.observer = observer
    },
    async fetchAspsps () {
      this.isLoading = true
      try {
        const response = await fetch(
          `${this.brokerOrigin}/api/aspsps?&sandbox=${encodeURIComponent(this.sandbox)}&country=${encodeURIComponent(this.country)}&psu_type=${encodeURIComponent(this.psuType)}&service=${encodeURIComponent(this.service)}`,
          {
            method: 'GET',
            mode: 'cors'
          }
        )

        const data = await response.json()
        this.aspsps = data.aspsps
        const event = new CustomEvent('ready',
          {
            detail: {}
          })
        this.$el.parentElement.dispatchEvent(event)
      } catch (error) {
        const event = new CustomEvent('error',
          {
            detail: { error: error }
          })
        this.$el.parentElement.dispatchEvent(event)
      } finally {
        this.isLoading = false
      }
    },
    handleCardClick (e) {
      const event = new CustomEvent('selected',
        {
          detail: { country: this.country, name: e.title, beta: e.beta, psuType: this.psuType, sandbox: this.sandbox, service: this.service }
        })
      this.$el.parentElement.dispatchEvent(event)
    },
    filteredCardsFunc (term) {
      this.filteredCards = this.aspsps
        .filter(card => {
          return card.name.toLowerCase().includes(term.toLowerCase())
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  computed: {
    filteredCards () {
      return this.aspsps
        .filter(card => card.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    combinedAttributes () {
      return `${this.brokerOrigin}-${this.sandbox}-${this.country}-${this.psuType}-${this.service}`
    }
  },
  watch: {
    combinedAttributes: {
      handler: async function (val, oldVal) {
        await this.fetchAspsps()
      }
    },
    searchTerm: {
      handler: function (val, oldVal) {
        this.filteredCardsFunc(val)
      }
    }
  }
}
</script>

<style scoped>
.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 0.5rem;
}
</style>
