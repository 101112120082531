import Vue from 'vue'
import vueCustomElement from 'vue-custom-element'
import Consent from './widgets/Consent.vue'
import AspspsList from './widgets/AspspsList.vue'
import AuthFlow from './widgets/AuthFlow.vue'
import { i18n } from './i18n'

Vue.use(vueCustomElement)

Vue.customElement('tilisy-consent', Consent) // depercated consent widget

Vue.customElement('enablebanking-consent', Consent)

Vue.customElement('enablebanking-aspsp-list', AspspsList)

Vue.customElement('enablebanking-auth-flow', AuthFlow)

window.tilisy = {
  i18n: i18n
}

window.enablebanking = {
  i18n: i18n
}
